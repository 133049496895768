import React, { ReactChildren } from "react"

export interface AlignmentSerializerProps {
  children: ReactChildren
}

export const AlignCenter: React.FC<AlignmentSerializerProps> = ({ children }) => {
  return <div style={{ textAlign: `center` }}>{children}</div>
}

export const AlignLeft: React.FC<AlignmentSerializerProps> = ({ children }) => {
  return <div style={{ textAlign: `left` }}>{children}</div>
}

export const AlignRight: React.FC<AlignmentSerializerProps> = ({ children }) => {
  return <div style={{ textAlign: `right` }}>{children}</div>
}
