import React, { CSSProperties, useState, useEffect } from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { GatsbyImageDataArgs, getGatsbyImageData } from "gatsby-source-sanity"

const stylesImg: CSSProperties = {
  objectFit: `contain`,
  minWidth: `160px`,
  maxWidth: `100%`,
  padding: `10px`,
  cursor: `pointer`,
}

const stylesWrap: CSSProperties = {
  display: `flex`,
  width: `100%`,
  flexWrap: `wrap`,
  justifyContent: `space-evenly`,
}

export interface Dimensions {
  _type: string
  aspectRatio: number
  height: number
  width: number
}

export interface DarkMuted {
  _type: string
  background: string
  foreground: string
  population: number
  title: string
}

export interface DarkVibrant {
  _type: string
  background: string
  foreground: string
  population: number
  title: string
}

export interface Dominant {
  _type: string
  background: string
  foreground: string
  population: number
  title: string
}

export interface LightMuted {
  _type: string
  background: string
  foreground: string
  population: number
  title: string
}

export interface LightVibrant {
  _type: string
  background: string
  foreground: string
  population: number
  title: string
}

export interface Muted {
  _type: string
  background: string
  foreground: string
  population: number
  title: string
}

export interface Vibrant {
  _type: string
  background: string
  foreground: string
  population: number
  title: string
}

export interface Palette {
  _type: string
  darkMuted: DarkMuted
  darkVibrant: DarkVibrant
  dominant: Dominant
  lightMuted: LightMuted
  lightVibrant: LightVibrant
  muted: Muted
  vibrant: Vibrant
}

export interface Metadata {
  _type: string
  blurHash: string
  dimensions: Dimensions
  hasAlpha: boolean
  isOpaque: boolean
  lqip: string
  palette: Palette
}

export interface Internal {
  type: string
  contentDigest: string
  owner: string
  counter: number
}

export interface Asset {
  _id: string
  _type: string
  _rev: string
  _createdAt: Date
  _updatedAt: Date
  assetId: string
  extension: string
  metadata: Metadata
  mimeType: string
  originalFilename: string
  path: string
  sha1hash: string
  size: number
  uploadId: string
  url: string
  id: string
  children: any[]
  internal: Internal
  parent?: any
}

export interface Image extends IGatsbyImageData {
  _key: string
  _type: string
  asset: Asset
  caption: string | null
  alt: string | null
}

export interface Gallery {
  _id: string
  _type: GalleryType
  _rev: string
  _createdAt: Date
  _updatedAt: Date
  name?: string
  id: string
  children: any[]
  internal: Internal
  parent: null
  images?: Image[]
  title?: string
}

export enum GalleryType {
  Gallery = `gallery`,
  Tag = `tag`,
}

export interface GalleryReference extends Gallery {
  reference?: Gallery
}

export interface GalleryInterface {
  node: GalleryReference
}

const GallerySerializer: React.FC<GalleryInterface> = ({ node }) => {
  const [galleryData, setGalleryData] = useState<Array<Image> | null>(null)

  const { reference } = node
  const [show, setShow] = useState(false)
  const [popUpImageAsset, setPopUpImageAsset] = useState<IGatsbyImageData | null>(null)
  const [popUpImageCaption, setPopUpImageCaption] = useState<string | null>(``)
  const [hideCaption, setHideCaption] = useState(false)

  const handleShow = (img: Image) => {
    const optionsObj: GatsbyImageDataArgs = {
      placeholder: `blurred`,
    }
    if (img.asset.metadata.dimensions.aspectRatio >= 1) {
      optionsObj.width = Math.min(window.innerWidth, img.asset.metadata.dimensions.width)
    } else {
      optionsObj.height = Math.min(window.innerHeight, img.asset.metadata.dimensions.height)
    }

    const largerImage: IGatsbyImageData | null = getGatsbyImageData(img.asset.id, optionsObj, {
      projectId: `zlybple6`,
      dataset: `production`,
    })

    setPopUpImageAsset(largerImage)
    setPopUpImageCaption(img.caption)
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
    setHideCaption(false)
  }

  useEffect(() => {
    if (reference?.images) {
      setGalleryData(reference.images)
    } else {
      setGalleryData(node?.images || null)
    }
  }, [reference])

  return (
    <>
      <div
        style={{
          position: `fixed`,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `black`,
          zIndex: 1,
          display: show ? `flex` : `none`,
          justifyContent: `center`,
          alignItems: `center`,
        }}
      >
        <button
          className="button secondary"
          style={{
            position: `absolute`,
            top: 0,
            left: 0,
            zIndex: 2,
          }}
          type="button"
          onClick={() => handleClose()}
        >
          Close
        </button>
        {popUpImageAsset && (
          <GatsbyImage
            style={{
              maxHeight: `100vh`,
            }}
            imgStyle={{
              objectFit: `contain`,
            }}
            alt=""
            image={popUpImageAsset}
          />
        )}
        {!hideCaption && popUpImageCaption && (
          <figcaption
            style={{
              position: `absolute`,
              bottom: 0,
              right: 0,
              maxWidth: `50%`,
              zIndex: 2,
              color: `white`,
              background: `black`,
              padding: `12px`,
              display: `relative`,
            }}
          >
            {popUpImageCaption}
            <button
              style={{
                position: `absolute`,
                top: `-10px`,
                left: `-10px`,
              }}
              type="button"
              onClick={() => setHideCaption(true)}
            >
              x
            </button>
          </figcaption>
        )}
      </div>
      <div style={stylesWrap}>
        {galleryData &&
          galleryData.map((img) => {
            const image =
              img?.asset?.id &&
              getGatsbyImageData(
                img.asset.id,
                { width: 250, height: 250, placeholder: `blurred` },
                { projectId: `zlybple6`, dataset: `production` }
              )

            return (
              <>
                {image && (
                  <div style={{ width: `270px` }} key={img.asset.id}>
                    <button
                      style={{
                        border: `none`,
                        background: `none`,
                        ...stylesImg,
                      }}
                      type="button"
                      onClick={() => handleShow(img)}
                    >
                      <GatsbyImage
                        imgStyle={stylesImg ? { ...stylesImg } : {}}
                        className="tiny-no-margin"
                        alt={img.alt || ``}
                        image={image}
                      />
                    </button>
                    {img.caption && (
                      <figcaption
                        style={{
                          textAlign: `center`,
                          fontSize: `.7rem`,
                          margin: `auto`,
                          maxWidth: `70%`,
                          padding: `0px 10px 10px 10px`,
                        }}
                      >
                        {img.caption}
                      </figcaption>
                    )}
                  </div>
                )}
              </>
            )
          })}
      </div>
    </>
  )
}

export default GallerySerializer
