import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import asideSerializers from "./asideSerializer"

interface AsideSerializerProps {
  node: {
    label: string | undefined
    category: string | undefined
    content: string | undefined
  }
}

const AsideSerializer: React.FC<AsideSerializerProps> = ({ node }) => {
  const { label, category, content } = node

  let icon

  switch (category) {
    case `info`:
      icon = `Info`
      break
    case `warning`:
      icon = `Warning`
      break
    case `danger`:
      icon = `Danger`
      break
    default:
      icon = `Info`
  }

  return (
    <div
      style={{
        display: `flex`,
      }}
      className={`article-aside-${category}`}
    >
      <div>
        <span
          className="article-aside-header"
          style={{
            display: `inline-block`,
            paddingBottom: `10px`,
            paddingLeft: `15px`,
            paddingTop: `2px`,
          }}
        >
          {label}
        </span>
        <div
          style={{
            paddingLeft: `15px`,
          }}
          className="article-aside-content"
        >
          <BlockContent blocks={content} serializers={asideSerializers} />
        </div>
      </div>
    </div>
  )
}

export default AsideSerializer
