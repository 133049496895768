import commonMarks from "./sharedMarks"
import commonTypes from "./sharedTypes"

const asideSerializers = {
  types: {
    ...commonTypes,
  },
  marks: {
    ...commonMarks,
  },
}

export default asideSerializers
