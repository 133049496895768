import React from "react"
import Refractor from "react-refractor"

// Load any languages you want to use from `refractor`
import js from "refractor/lang/javascript"
import python from "refractor/lang/python"
import bash from "refractor/lang/bash"
import markdown from "refractor/lang/markdown"
import typescript from "refractor/lang/typescript"

import "prism-themes/themes/prism-vsc-dark-plus.css"

// Then register them
Refractor.registerLanguage(js)
Refractor.registerLanguage(python)
Refractor.registerLanguage(bash)
Refractor.registerLanguage(markdown)
Refractor.registerLanguage(typescript)

export interface CodeSerializerProps {
  node: {
    language: string
    code: string
  }
}

const CodeSerializer: React.FC<CodeSerializerProps> = ({ node }) => {
  let lang = node.language
  if (lang === `text`) {
    lang = `bash`
  } else if (!lang) {
    lang = `bash`
  }

  return <Refractor language={lang} value={node.code} />
}

export default CodeSerializer
