import React, { ReactChildren } from "react"

export interface LinkSerializerProps {
  mark: {
    blank: boolean | null
    href: "" | null
  }
  children: ReactChildren
}

const LinkSerializer: React.FC<LinkSerializerProps> = ({ mark, children }) => {
  const { blank, href } = mark
  return (
    <>
      {blank ? (
        <a href={href || `#`} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a href={href || `#`}>{children}</a>
      )}
    </>
  )
}

export default LinkSerializer
