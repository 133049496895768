import { Link } from "gatsby"
import React, { ReactChildren } from "react"

type Mark = {
  reference: {
    slug: { current: string }
  }
}

export interface InternalLinkProps {
  mark: Mark
  children: ReactChildren
}

const InternalLink: React.FC<InternalLinkProps> = ({ mark, children }) => {
  const { reference = { slug: { current: `` } } } = mark
  const { slug } = reference
  const href = `/posts/${slug.current || ``}`
  return (
    <Link style={{ color: `#ec4c12` }} to={href}>
      {children}
    </Link>
  )
}

export default InternalLink
