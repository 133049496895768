import AsideSerializer from "./Aside"
import commonMarks from "./sharedMarks"
import commonTypes from "./sharedTypes"

const serializers = {
  types: {
    aside: AsideSerializer,
    ...commonTypes,
  },
  marks: {
    ...commonMarks,
  },
}

export default serializers
