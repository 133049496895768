import React, { useState } from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { GatsbyImageDataArgs, getGatsbyImageData } from "gatsby-source-sanity"
import { Image } from "./Gallery"

export interface ImageSerializerProps {
  node: Image
}

const ImageSerializer: React.FC<ImageSerializerProps> = ({ node }) => {
  const [image, setImage] = useState<IGatsbyImageData | null>(null)

  React.useEffect(() => {
    const params: GatsbyImageDataArgs = {
      placeholder: `blurred`,
    }

    let imageData

    if (node?.asset?.id) {
      imageData = getGatsbyImageData(node.asset.id, params, { projectId: `zlybple6`, dataset: `production` })
    }

    if (imageData) {
      setImage(imageData)
    }
  }, [node?.asset?.id])

  return (
    <>
      {image && (
        <figure>
          <GatsbyImage
            image={image}
            className="sanity-image"
            imgStyle={{
              objectFit: `contain`,
            }}
            alt={node.alt || ``}
          />
          <figcaption>{node.caption}</figcaption>
        </figure>
      )}
    </>
  )
}

export default ImageSerializer
