import React from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { SanityReference } from "@sanity/image-url/lib/types/types"
import { Image } from "./Gallery"

interface MapLinkReference extends SanityReference {
  reference: {
    _id: string | null
    placeholder: Image
  }
}

interface MapLinkSerializerProps {
  node: MapLinkReference
}

const MapLinkSerializer: React.FC<MapLinkSerializerProps> = ({ node }) => {
  const { reference } = node
  const { placeholder } = reference

  const image: IGatsbyImageData | null = getGatsbyImageData(
    placeholder.asset.id,
    {
      placeholder: `blurred`,
    },
    { projectId: `zlybple6`, dataset: `production` }
  )
  return (
    <>
      {image && (
        <div className="interactive-map-link-wrapper">
          <a
            style={{ position: `relative`, display: `flex`, justifyContent: `center`, alignItems: `center` }}
            target="_blank"
            rel="noopener noreferrer"
            className="interactive-map-link-link"
            href={`https://maps.retz.blog/${reference._id}`}
          >
            <span
              style={{
                position: `absolute`,
                zIndex: 1,
                padding: `12px 8px`,
                borderRadius: `8px`,
              }}
              className="background-primary color-secondary"
            >
              Go to Interactive Map
              {` `}
            </span>
            <GatsbyImage className="interactive-map-link-image" image={image} alt={placeholder.alt || ``} />
          </a>
          <div />
        </div>
      )}
    </>
  )
}

export default MapLinkSerializer
