import React, { ReactChildren } from "react"
import BlockContent from "@sanity/block-content-to-react"

export interface BlockSerializerProps {
  node: {
    style: string | "normal"
  }
  children: ReactChildren
}

const BlockSerializer: React.FC<BlockSerializerProps> = (props) => {
  const { node, children } = props
  const { style } = node

  if (style === `blockquote`) {
    return <blockquote className="blockquote quote">{children}</blockquote>
  }
  return BlockContent.defaultSerializers.types.block(props)
}

export default BlockSerializer
