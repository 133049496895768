import GallerySerializer from "./Gallery"
import ImageSerializer from "./Image"
import CodeSerializer from "./Code"
import YoutubeSerializer from "./Youtube"
import BlockSerializer from "./Block"
import SeriesListSerializer from "./SeriesList"
import MapLinkSerializer from "./MapLink"
import HikeSerializer from "./Hike"

const commonTypes = {
  galleryBlockContent: GallerySerializer,
  code: CodeSerializer,
  imageBlockContent: ImageSerializer,
  youtube: YoutubeSerializer,
  block: BlockSerializer,
  seriesList: SeriesListSerializer,
  mapBlockContent: MapLinkSerializer,
  hikeBlockContent: HikeSerializer,
}

export default commonTypes
