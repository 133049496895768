import getYouTubeId from "get-youtube-id"
import YouTube, { Options } from "react-youtube"
import React from "react"

export interface YoutubeSerializerProps {
  node: {
    url: string | null
  }
}

const YoutubeSerializer: React.FC<YoutubeSerializerProps> = ({ node }) => {
  const { url } = node
  const wrapperEl = React.useRef<HTMLDivElement>(null)
  const [wrapperWidth, setWrapperWidth] = React.useState<number>(0)

  React.useEffect(() => {
    if (typeof window !== undefined && wrapperEl && wrapperEl.current) {
      setWrapperWidth(wrapperEl.current.offsetWidth || 0)
    }
  }, [setWrapperWidth, wrapperEl])

  const id: string | null = getYouTubeId(url || ``)

  const wrapperStyle = {
    height: `100%`,
    width: `100%`,
  }

  const youtubeOptions: Options = {
    height: `${Math.round((wrapperWidth || 0) / 1.83)}px`,
    width: `100%`,
  }

  return (
    <>
      <div ref={wrapperEl} style={wrapperStyle}>
        <YouTube
          videoId={id || ``}
          className="sanity-youtube"
          containerClassName="sanity-youtube-container"
          opts={youtubeOptions}
        />
      </div>
    </>
  )
}

export default YoutubeSerializer
