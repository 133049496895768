import { graphql, Link } from "gatsby"
import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import { GatsbyImage } from "gatsby-plugin-image"
import serializers from "../../../serializers/serializers"
import SEO from "../../SEO/SEO"
import GoBack from "../../navigation/GoBack/GoBack"
import Subscribe from "../../Subscribe/Subscribe"
import { PostProps } from "./types"

const Content: React.FC<PostProps> = ({ data }) => {
  const currentPostIndex = data?.allSanityPost?.edges?.findIndex((item) => item.node.title === data?.sanityPost?.title)

  const fallbackPrevPost =
    currentPostIndex && data?.allSanityPost?.edges && data?.allSanityPost?.edges[currentPostIndex + 1]?.node
  const fallbackNextPost =
    currentPostIndex && data?.allSanityPost?.edges && data?.allSanityPost?.edges[currentPostIndex - 1]?.node

  return (
    <div
      style={{
        maxWidth: `680px`,
        margin: `auto`,
      }}
    >
      <SEO
        title={data.sanityPost.title}
        description={data.sanityPost.excerpt}
        image={data.sanityPost.mainImage.asset.url}
        article
      />
      <div
        style={{
          display: `flex`,
          justifyContent: `space-between`,
          paddingBottom: `16px`,
        }}
      >
        <GoBack />
      </div>
      <GatsbyImage
        style={{
          maxHeight: `30vh`,
          width: `100%`,
        }}
        alt=""
        image={data.sanityPost.mainImage.asset.gatsbyImageData}
      />
      <h1>{data.sanityPost.title || ``}</h1>
      {data.sanityPost.author && (
        <span
          id="author"
          style={{
            display: `block`,
          }}
        >
          by <b>{data.sanityPost.author.name}</b>
        </span>
      )}
      <span
        id="article-date"
        style={{
          display: `block`,
          paddingTop: `8px`,
        }}
      >
        {data.sanityPost.publishedAt}
      </span>
      <article
        id="article-content"
        style={{
          paddingTop: `40px`,
          paddingBottom: `64px`,
          maxWidth: `680px`,
          margin: `auto`,
        }}
        className="pt-3"
      >
        <hr />
        <BlockContent blocks={data.sanityPost._rawBody} serializers={serializers} />
      </article>
      <hr style={{ margin: `32px 0px` }} />
      <div style={{ display: `flex`, justifyContent: `space-between` }}>
        {data?.sanityPost?.prevArticle?.slug?.current ? (
          <div style={{ flex: `0 0 40%` }}>
            <p className="label" style={{ fontStyle: `normal` }}>
              Previous
            </p>
            <Link to={`/posts/${data.sanityPost.prevArticle.slug.current}`}>{data.sanityPost.prevArticle.title}</Link>
          </div>
        ) : (
          <>
            {fallbackPrevPost ? (
              <div style={{ flex: `0 0 40%` }}>
                <p className="label" style={{ fontStyle: `normal` }}>
                  Previous
                </p>
                <Link to={`/posts/${fallbackPrevPost.slug.current}`}>{fallbackPrevPost.title}</Link>
              </div>
            ) : null}
          </>
        )}
        {data?.sanityPost?.nextArticle?.slug?.current ? (
          <div style={{ flex: `0 0 40%` }}>
            <p className="label" style={{ fontStyle: `normal` }}>
              Next
            </p>
            <Link to={`/posts/${data.sanityPost.nextArticle.slug.current}`}>{data.sanityPost.nextArticle.title}</Link>
          </div>
        ) : (
          <>
            {fallbackNextPost ? (
              <div style={{ flex: `0 0 40%` }}>
                <p className="label" style={{ fontStyle: `normal` }}>
                  Next
                </p>
                <Link to={`/posts/${fallbackNextPost.slug.current}`}>{fallbackNextPost.title}</Link>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  )
}
export default Content

export const postDetails = graphql`
  fragment PostDetails on SanityPost {
    _rawBody(resolveReferences: { maxDepth: 10 })
    author {
      ...AuthorDetails
    }
    categories {
      ...CategoryDetails
    }
    excerpt
    lead
    mainImage {
      asset {
        gatsbyImageData(placeholder: BLURRED)
        url
      }
    }
    series {
      isSeries
      title
      slug {
        current
      }
      _id
    }
    title
    tags {
      ...TagDetails
    }
    slug {
      current
    }
    publishedAt(formatString: "MMMM Do, YYYY")
    related
    prevArticle {
      title
      slug {
        current
      }
    }
    nextArticle {
      title
      slug {
        current
      }
    }
  }
`
