import InternalLink from "./InternalLink"
import LinkSerializer from "./Link"
import { AlignCenter, AlignLeft, AlignRight } from "./Alignment"

const commonMarks = {
  link: LinkSerializer,
  internalLink: InternalLink,
  left: AlignLeft,
  center: AlignCenter,
  right: AlignRight,
}

export default commonMarks
