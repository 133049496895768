import React from "react"
import { Link } from "gatsby"
import client from "../util/sanityClient"

export interface SeriesListSerializerProps {
  node: {
    series: {
      _id: string
    }
  }
}

const SeriesListSerializer: React.FC<SeriesListSerializerProps> = ({ node }) => {
  const { series } = node

  const [titles, setTitles] = React.useState<Array<{ title: string; slug: string }> | null>(null)

  const fetchSeriesTitles = React.useCallback(() => {
    const query = `*[_type == "post" && references("${series._id}")]{ title, "slug": slug.current, publishedAt } | order(publishedAt asc)`

    client.fetch(query).then((articles) => {
      setTitles(articles)
    })
  }, [series._id])

  console.log(titles)

  React.useEffect(() => {
    if (series?._id) {
      fetchSeriesTitles()
    }
  }, [fetchSeriesTitles, series?._id])

  return (
    <div className="py-3">
      <ul>
        {titles &&
          titles.map((article) => {
            return (
              <li className="pb-1">
                <Link to={`/posts/${article.slug}`}>{article.title}</Link>
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default SeriesListSerializer
