import React from "react"
import { navigate } from "gatsby"

const GoBack: React.FC = () => {
  const previous = () => navigate(-1)

  return (
    <button className="button secondary" type="button" onClick={previous}>
      Back
    </button>
  )
}

export default GoBack
